"use client";
import { IconButton, Stack } from "@mui/material";
import { ReactNode } from "react";
import {
  MotionBox,
  MotionTypography,
  MotionTypographyProps,
} from "@/commonComponents/motion-components";
import SectionWrapper from "@/commonComponents/section-wrapper";
import { H3_2, H6_2 } from "@/styles/theme/components/typography.fontvariant";
import { videoBannerTransition } from "@/commonComponents/animations";
import { PlayArrowRounded } from "@mui/icons-material";
import Image from "next/image";
import dynamic from "next/dynamic";

// Dynamic Imports
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

export interface AboveProfitsProps {
  title?: string;
  description?: string;
  video: string;
  poster?: string;
}

const AboveProfits = (props: AboveProfitsProps) => {
  const { title, description, poster, video } = props;

  return (
    <SectionWrapper
      showSectionBgTransactionProps
      SectionProps={{
        sx: {
          background: (theme) => theme.palette.gradient.darkToLight,
        },
      }}
      color={"secondary.main"}
      SectionHeaderProps={{
        title,
        TitleTypographyProps: {
          fontSize: H3_2,
        },
        mb: 0,
      }}
    >
      <Stack textAlign={"center"} width={1} maxWidth={1052} m={"auto"}>
        {video && (
          <VideoWrapper>
            <ReactPlayer
              url={video}
              width={"100%"}
              height={"100%"}
              light={
                poster && (
                  <Image
                    src={poster}
                    alt={title || "video"}
                    width={1050}
                    height={510}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                )
              }
              controls
              playing
              playIcon={
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    width: { xs: 64, md: 80 },
                    height: { xs: 64, md: 80 },
                    borderRadius: "50%",
                    bgcolor: (theme) => theme.palette.primary.main,
                    "&:hover": {
                      bgcolor: (theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  <PlayArrowRounded color={"secondary"} fontSize={"large"} />
                </IconButton>
              }
            />
          </VideoWrapper>
        )}
        {description && (
          <MotionTypography variant="body1" fontSize={H6_2} {...descTransition}>
            {description}
          </MotionTypography>
        )}
      </Stack>
    </SectionWrapper>
  );
};

export default AboveProfits;

const VideoWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <MotionBox
      position={"relative"}
      width={1}
      sx={{
        aspectRatio: "16/9",
      }}
      mt={{ xs: 4, md: 6 }}
      mb={{ xs: 3, md: 4 }}
      zIndex={1}
      {...videoBannerTransition}
    >
      {children}
    </MotionBox>
  );
};

const descTransition: MotionTypographyProps = {
  initial: {
    y: "-130%",
    opacity: 0,
  },
  whileInView: {
    y: 0,
    opacity: 1,
  },
  transition: {
    duration: 0.5,
    delay: 0.8,
  },
  viewport: {
    once: true,
  },
};
