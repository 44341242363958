"use client";
import { Stack } from "@mui/material";
import OurStories, { OurStoriesProps } from "@/components/home/our-stories";
import KeyHighlights, {
  KeyHighlightsProps,
} from "@/components/home/key-highlights";
import { sxArrayUtil } from "@util/sx-helpers";
import { defaultSectionBgTransactionProps } from "@cc/section-wrapper";

export interface OurStoriesAndKeyHighlightsProps {
  storiesSection?: OurStoriesProps;
  keyHighlightsSection?: KeyHighlightsProps;
}

const OurStoriesAndKeyHighlights = (props: OurStoriesAndKeyHighlightsProps) => {
  const { storiesSection, keyHighlightsSection } = props;

  if (
    (!storiesSection || !(storiesSection.stories.length > 0)) &&
    (!keyHighlightsSection || !(keyHighlightsSection.highlights.length > 0))
  )
    return null;
  return (
    <Stack
      width={1}
      sx={[
        {
          overflow: "hidden",
          background: (theme) => theme.palette.gradient.darkToLight,
        },
        ...sxArrayUtil(
          defaultSectionBgTransactionProps({
            bottom: { xs: "24.5%", lg: "22%" },
            top: "unset",
          }),
        ),
      ]}
    >
      {storiesSection && storiesSection.stories.length > 0 && (
        <OurStories {...storiesSection} />
      )}
      {keyHighlightsSection && keyHighlightsSection.highlights.length > 0 && (
        <KeyHighlights {...keyHighlightsSection} />
      )}
    </Stack>
  );
};

export default OurStoriesAndKeyHighlights;
