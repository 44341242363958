"use client";
import { Box, Slide, Stack } from "@mui/material";
import React, { useState } from "react";
import { MotionImageProps, MotionVideo } from "@cc/motion-components";
import ArrowColor from "@cc/arrow-color";
import { useEffectOnce } from "react-use";

interface VideoBannerProps {
  videos: string[];
}

const VideoBanner = (props: VideoBannerProps) => {
  const { videos } = props;
  const [playableVideo, setPlayableVideo] = useState<string>("");

  useEffectOnce(() => {
    setPlayableVideo(videos[Math.floor(Math.random() * videos.length)]);
  });

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
      width={{ xs: 270, sm: 395, lg: 450, lg_xl: 470, xl_xxl: 530 }}
      height={{ xs: 276, sm: 412, lg: 472, lg_xl: 492, xl_xxl: 542 }}
    >
      <Slide
        direction="right"
        in
        timeout={500}
        easing={{ enter: "cubic-bezier(.13,.47,.02,1)" }}
      >
        <ArrowColor
          height={"112%"}
          sx={{
            left: { xs: -54, sm: -30, lg: -15, lg_xl: -10, xl_xxl: 0 },
            position: "absolute",
            zIndex: 1,
          }}
        />
      </Slide>
      <Box
        sx={{
          position: "relative",
          ml: "auto",
          width: 1,
          height: 1,
          overflow: "hidden",
          clipPath:
            "polygon(75% 0%, 100% 50%, 74% 100%, 0% 100%, 25% 50%, 0 0)",
        }}
      >
        {playableVideo && (
          <MotionVideo
            src={playableVideo}
            autoPlay
            muted
            loop
            playsInline
            initial={imageTransition.initial}
            animate={imageTransition.animate}
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              clipPath: "inherit",
            }}
          />
        )}
      </Box>
    </Stack>
  );
};
export default VideoBanner;

const imageTransition: Omit<MotionImageProps, "src" | "alt"> = {
  initial: {
    x: "-100%",
  },
  animate: {
    x: 0,
    transition: {
      duration: 0.8,
      delay: 0.8,
    },
  },
};
