"use client";
import {
  MotionStack,
  MotionTypography,
} from "@/commonComponents/motion-components";
import { Stack, Theme, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import { SmallTitleCard } from "@/commonComponents/cards/small-title";
import SectionWrapper from "@/commonComponents/section-wrapper";
import {
  H3_1,
  H6_2,
  H6_3,
} from "@/styles/theme/components/typography.fontvariant";
import ArrowSwiperWithInfoSection, {
  ArrowSwiperWithInfoProps,
} from "@/commonComponents/arrow-swiper-with-info-section";
import {
  arrowInfoStaggerDiv,
  arrowInfoStaggerDivChildren,
  arrowLeftToRightTransition,
} from "@/commonComponents/animations";
import ArrowGradient from "@cc/arrow-gradient";
import EnhancedSwiper from "@cc/enhanced-swiper";

export interface KeyFocusSectionProps {
  title?: string;
  description?: string;
  initiatives: ArrowSwiperWithInfoProps["data"];
}

const KeyFocusSection = (props: KeyFocusSectionProps) => {
  const { title, description, initiatives = [] } = props;
  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const firstInitiative = useMemo(() => initiatives[0], [initiatives]);
  const restInitiatives = useMemo(() => initiatives.slice(1), [initiatives]);

  return (
    <SectionWrapper
      pt={{ xs: 7, md: 6 }}
      pb={{ xs: 7, md: 12 }}
      rowGap={{ xs: 6, md: 2 }}
      color={"primary.main"}
    >
      {(title || description) && (
        <Stack direction={"row"} alignItems={"center"}>
          {matches && (
            <ArrowGradient height={"301px"} {...arrowLeftToRightTransition} />
          )}
          <MotionStack
            variants={arrowInfoStaggerDiv}
            initial={"initial"}
            whileInView={"animate"}
            viewport={{ once: true }}
            maxWidth={{ xs: "100%", md: 814 }}
            rowGap={4}
          >
            <Stack maxWidth={814} rowGap={{ xs: 2, md: 4 }}>
              {title && (
                <MotionTypography
                  variant="h1"
                  component={"h3"}
                  variants={arrowInfoStaggerDivChildren}
                >
                  {title}
                </MotionTypography>
              )}
              {description && (
                <MotionTypography
                  fontSize={H6_3}
                  variants={arrowInfoStaggerDivChildren}
                >
                  {description}
                </MotionTypography>
              )}
            </Stack>
          </MotionStack>
        </Stack>
      )}
      {firstInitiative && (
        <ArrowSwiperWithInfoSection
          data={[firstInitiative]}
          SwiperKey={KeyFocusSection.name}
          SwiperProps={{
            navigation: false,
          }}
          ArrowSlideInfoProps={{
            SlotProps: {
              TitleTypographyProps: {
                fontSize: H3_1,
                maxWidth: "96%",
              },
              ButtonProps: {
                variant: "contained",
                color: "primary",
                sx: {
                  fontSize: H6_2,
                  p: "12px 24px !important",
                },
              },
            },
          }}
          SectionWrapperProps={{
            p: "0 !important",
            mb: { xs: 0, md: 6 },
          }}
        />
      )}
      {restInitiatives.length > 0 && (
        <Stack
          direction="row"
          alignItems={"center"}
          width={{ xs: 1, xxl: "107%" }}
        >
          <EnhancedSwiper
            slidesPerView={"auto"}
            SlideWrapperProps={{
              sx: {
                width: { xs: 200, lg: 328 },
                // maxWidth: { xs: 200, lg: 328 },
                mr: { xs: 2, lg: 0 },
                transition: "all 0.3s",
                "&:hover": {
                  transform: "scale(1.1)",
                  zIndex: 1,
                },
              },
            }}
            sx={{
              m: {
                xxl: "-64px 0 -96px -64px",
              },
              p: {
                xxl: "64px 64px 96px 64px",
              },
              overflow: { xs: "visible", xxl: "hidden" },
            }}
            SlideComponent={SmallTitleCard}
            data={restInitiatives.map((item) => {
              return {
                img: item.image,
                title: item.title,
                href: item.btnLink,
              };
            })}
            SlideComponentProps={(index) => ({
              initial: {
                opacity: 0,
                y: "100%",
              },
              whileInView: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.6,
                  delay: index * 0.5,
                },
              },
              viewport: {
                once: true,
              },
            })}
          />
        </Stack>
      )}
    </SectionWrapper>
  );
};

export default KeyFocusSection;
