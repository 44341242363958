"use client";
import {
  MotionStack,
  MotionStackProps,
} from "@/commonComponents/motion-components";
import SectionWrapper from "@/commonComponents/section-wrapper";
import { Stack, Typography } from "@mui/material";
import {
  arrowImageTransition,
  formStagger,
  formStaggerChildrenRight,
} from "@/commonComponents/animations";
import { I18nLocale } from "@/i18nConfig.server";
import dynamic from "next/dynamic"; // Dynamic Imports
import ArrowImage from "@/commonComponents/arrow-image";

// Dynamic Imports
const ContactUsForm = dynamic(() => import("@cc/contact-us-form"));

interface ContactUsSectionProps {
  title: string;
  subtitle?: string;
  image: string;
  locale: I18nLocale;
}

const ContactUsSection = (props: ContactUsSectionProps) => {
  const { title, subtitle, image, locale } = props;

  return (
    <SectionWrapper
      SectionProps={{
        sx: {
          background: (theme) => theme.palette.gradient.lightToDark,
        },
      }}
    >
      <Stack
        direction={{ xs: "column", md_lg: "row" }}
        rowGap={3}
        columnGap={5}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <ContactUsHeader
          title={title}
          subtitle={subtitle}
          display={{ xs: "flex", md_lg: "none" }}
        />
        <ArrowImage
          src={image}
          alt={title}
          width={469}
          height={545}
          sx={{
            width: { xs: 342, xl: 469 },
            minWidth: { xs: 342, xl: 469 },
            height: { xs: 398, xl: 545 },
          }}
          ContainerProps={{ ...arrowImageTransition }}
        />
        <MotionStack
          rowGap={5}
          maxWidth={{ xs: 1, md_lg: 670 }}
          variants={formStagger}
          initial="initial"
          whileInView={"animate"}
          viewport={{ once: true }}
        >
          <ContactUsHeader
            title={title}
            subtitle={subtitle}
            display={{ xs: "none", md_lg: "flex" }}
          />
          <ContactUsForm locale={locale} />
        </MotionStack>
      </Stack>
    </SectionWrapper>
  );
};

export default ContactUsSection;

interface ContactUsHeaderProps extends Omit<MotionStackProps, "children"> {
  title?: string;
  subtitle?: string;
}

const ContactUsHeader = (props: ContactUsHeaderProps) => {
  const { title, subtitle, ...restStackProps } = props;
  return title || subtitle ? (
    <MotionStack
      gap={1}
      variants={formStaggerChildrenRight}
      {...restStackProps}
    >
      {title && <Typography variant="h3">{title}</Typography>}
      {subtitle && <Typography fontSize={"18px"}>{subtitle}</Typography>}
    </MotionStack>
  ) : null;
};
