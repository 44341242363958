import ScrollTriggeredSwiperSection, {
  ScrollTriggeredSwiperSectionProps,
} from "@cc/scrollTriggeredSwiperSection";

export interface OurStoriesProps {
  title?: string;
  stories: ScrollTriggeredSwiperSectionProps["data"];
}

const OurStories = (props: OurStoriesProps) => {
  const { title, stories } = props;
  return (
    <ScrollTriggeredSwiperSection
      SectionHeaderProps={{
        title,
      }}
      ContainerProps={{
        pb: {
          xs: 0,
          md: 1.5,
          xxl: 3,
        },
      }}
      data={stories}
    />
  );
};

export default OurStories;
