import ArrowSwiperWithInfoSection, {
  ArrowSwiperWithInfoProps,
} from "@cc/arrow-swiper-with-info-section";
import { H3_1 } from "@theme/components/typography.fontvariant";

export interface KeyHighlightsProps {
  title?: string;
  highlights: ArrowSwiperWithInfoProps["data"];
}

const KeyHighlights = (props: KeyHighlightsProps) => {
  const { title, highlights } = props;
  return (
    <ArrowSwiperWithInfoSection
      data={highlights}
      SwiperKey={KeyHighlights.name}
      SectionWrapperProps={{
        color: "secondary.main",
        SectionHeaderProps: {
          title,
        },
      }}
      SwiperProps={{
        navigation: true,
      }}
      ArrowSlideInfoProps={{
        SlotProps: {
          TitleTypographyProps: {
            fontSize: H3_1,
          },
          ButtonProps: {
            color: "secondary",
          },
        },
      }}
    />
  );
};

export default KeyHighlights;
