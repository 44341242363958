"use client";
import React from "react";
import {
  MotionStack,
  MotionTypography,
  MotionVariantProps,
} from "@cc/motion-components";
import SectionWrapper, { basePx, basePy } from "@cc/section-wrapper";
import AnimatedButton, { AnimatedButtonProps } from "@cc/animated-button";
import VideoBanner from "@c/home/hero-section/home-page-hero-video";

export interface HomePageHeroSectionProps {
  title: string;
  description?: string;
  videos: string[];
  ButtonProps?: AnimatedButtonProps;
}

const HomePageHeroSection = (props: HomePageHeroSectionProps) => {
  const { videos, title, description, ButtonProps } = props;

  return (
    <SectionWrapper
      pt={{ ...basePy, md: 6.25, xxl: 6.25 }}
      pb={{ ...basePy, xxl: 8.25 }}
      px={{ ...basePx, xl: 16, xxl: 22 }}
      direction={{ xs: "column-reverse", md: "row" }}
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={{ xs: 4, md: 2, lg: 4, xl: 6 }}
      SectionProps={{
        sx: {
          background: (theme) => theme.palette.gradient.radialLightToDark,
        },
      }}
    >
      <MotionStack
        variants={staggerDiv}
        initial={"initial"}
        animate={"animate"}
        width={{ xs: 1, md: 0.5 }}
      >
        {title && (
          <MotionTypography
            variant="h1"
            color={"#fff"}
            sx={{ fontWeight: 400, mb: 2 }}
            variants={staggerChildren}
          >
            {title}
          </MotionTypography>
        )}
        {description && (
          <MotionTypography
            variant="body1"
            color={"secondary.main"}
            variants={staggerChildren}
          >
            {description}
          </MotionTypography>
        )}
        {ButtonProps && ButtonProps.children && ButtonProps.href && (
          <AnimatedButton
            sx={{
              mt: 4,
              width: { xs: "100%", md: "207px" },
              fontSize: { xs: "18px", md: "24px !important" },
            }}
            variant={"contained"}
            color={"secondary"}
            animationDelay={1000}
            variants={staggerChildren}
            {...ButtonProps}
          />
        )}
      </MotionStack>
      <VideoBanner videos={videos} />
    </SectionWrapper>
  );
};

export default HomePageHeroSection;

const staggerDiv: MotionVariantProps = {
  initial: {},
  animate: {
    transition: {
      staggerChildren: 0.4,
      delayChildren: 1.3,
    },
  },
};
const staggerChildren: MotionVariantProps = {
  initial: {
    opacity: 0,
    y: "30%",
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  },
};
